require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();
const _ = require('underscore');
require('slick-carousel');

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
require('../../common/js/videofinisher');
const { setupPlayers, teardownPlayers } = require('../../common/js/perform');
const utils = require('../../common/js/utils');

const VIDEOS_PER_PAGE = 15;
//---------------------------------------------------------------------------------
// Main
class Main {
  constructor () {
    this.mq = 320;
    this.overflowsCache = null;
    this.adjustOverflowsTimeout = 0;
  }

  isMobile () {
    return this.mq <= 320;
  }

  stopPlayback () {
    if (window.videojs && window.videojs.players &&
      window.videojs.players.performPlayer) {
      window.videojs.players.performPlayer.pause();
    }
  }

  init () {
    this.cacheOverflows();

    $('.expand-bar').on('click', e => {
      const $this = $(e.currentTarget);
      $this.hide();
      $this.siblings('.video-grid').addClass('video-grid-open');
      header.onWindowResize();
      window.bcGallery.imageLoader.loadImages();
    });

    $('.expand-bar-mobile').on('click', e => {
      const $this = $(e.currentTarget);
      $this.hide();
      $this.siblings('.video-grid').addClass('video-grid-open');
      header.onWindowResize();
      window.bcGallery.imageLoader.loadImages();
    });

    $('.results-pagination').on('keyup', '.pagination-current', e => {
      const $this = $(e.currentTarget);
      if (e.keyCode === 13) {// enter
        let newPage = parseInt($this.val()) - 1;
        const maxPage = parseInt($this.attr('data-bc-total-pages'));

        if (newPage < 0) {
          newPage = 0;
        } else if (newPage >= maxPage) {
          newPage = maxPage - 1;
        }

        $this.val(newPage + 1);

        let href = document.location.href;
        if (href.indexOf('?page=') >= 0 || href.indexOf('&page=') >= 0) {
          href = href.replace(/page=[0-9]+/, 'page=' + newPage);
        } else {
          if (href.indexOf('?') >= 0) {
            href += '&page=' + newPage;
          } else {
            href += '?page=' + newPage;
          }
        }

        document.location.href = utils.urlWithDevice(href);
      }
    });

    $('.load-more-bar-mobile').on('click', e => this.loadMoreVideos(e));
    $('.bc-sort-videos-mobile').on('shown.bs.modal', e => this.styleBackdrop(e));
    $('.bc-sort-videos-header').on('click', e => this.stopPlayback(e));
    $('.bc-search-button-mobile').on('click', e => this.stopPlayback(e));
    $('.bc-video-search-form').on('submit', e => this.handleSearchMobile(e));

  }

  handleSearch (event) {
    event.preventDefault();
    const searchTerm = $('.main-header-search-input').val();
    if (!searchTerm) {
      return;
    }
    document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + encodeURIComponent(searchTerm));
  }

  handleSearchMobile (event) {
    event.preventDefault();
    const searchTerm = encodeURIComponent($('#bc-video-search-form-value-mobile').val());
    if (!searchTerm) {
      document.location.href = '/';
    } else {
      document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + searchTerm);
    }
  }

  loadMoreVideos () {
    const nextPage = $('.load-more-bar-mobile').data('next-page');
    const match = /page=([a-z0-9]*)/i.exec(nextPage);
    let page;
    if (match && match.length > 0) {
      page = match[1];
    } else {
      page = Math.floor($('.video-grid').children().length / VIDEOS_PER_PAGE);
    }
    const q = window.query ? window.query.q : null;
    const category = window.category ? window.category.slug : '';
    this.loadVideos({ append: true, page: page, q: q, category: category });
    delete window.query.page;
  }

  loadVideos (params) {
    // reset but not everything so as to not break autoplay next
    if (window.query) {
      window.query.page = null;
      window.query.q = null;
    }

    $('.video-grid').fadeTo(0, 0.5);

    let route = '';

    // recursively loop through categories to find active category
    function recurse (category) {
      if (category.slug === params.category) {
        window.category = category;
        route = '/' + category.slug;
        return;
      }

      if (_.has(category, 'children')) {
        _.each(category.children, function (child) {
          recurse(child);
        });
      }
    }

    if (params.category) {
      _.each(window.categories, function (category) {
        recurse(category);
      });
    }

    if (params.page) {
      window.query.page = params.page;
    }

    if (params.q) {
      window.query.q = params.q;
    }
    if (params.sort_by) {
      window.query.sort_by = params.sort_by;
    }
    return $.ajax(window.baseUrl + '/api/videos' + route, {
      dataType: 'json',
      data: params,
      success: (data) => {
        this.appendVideos(data);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  appendVideos (data) {
    if (!data || !data.result || !data.result.items) {
      return;
    }
    const videos = data.result.items;

    const videoGridItemPath = window.bcGallery.getTemplatePath('/sites/the_classic-v2/partials/videoGridItem.hbs');

    carousel.loadTemplate(videoGridItemPath, function (template) {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i]) {
          const contextData = {
            baseUrl: window.baseUrl,
            imageTranscoder: window.BCLS.imageTranscoder,
            category: window.category,
            query: window.query,
            subPath: window.subPath,
          };
          const handlebarContext = _.extend({}, videos[i], contextData);
          $('.video-grid').append(template(handlebarContext));
        }
      }
      window.bcGallery.imageLoader.loadImages();
      $('.video-grid').fadeTo(0, 1);
      const $loadMoreButton = $('.load-more-bar-mobile');
      if (data.result.next) {
        $loadMoreButton.data('next-page', data.result.next);
      } else {
        $loadMoreButton.hide();
      }
    });
  }

  styleBackdrop () {
    const $backdrop = $('.modal-backdrop');
    if ($backdrop.length) {
      $('.modal-backdrop').css('opacity', '1');
      $('.modal-backdrop').css('background-color', 'black');
    }
  }

  cacheOverflows () {
    this.overflowsCache = [];

    let cacheId = 0;
    $('.video-grid-item').each((index, element) => {
      const $gridItem = $(element);
      $gridItem.attr('data-bc-cache-id', cacheId);
      this.overflowsCache[cacheId] = $gridItem.find('.video-grid-description').html();
      cacheId++;
    });
  }

  setMq () {
    const mq = $('body').css('z-index');
    this.mq = parseInt(mq, 10);
  }

  styledHeight ($element) {
    let styledHeight = $element.height();
    styledHeight += parseInt(($element.css('margin-top') || '0').replace('px', ''), 10);
    styledHeight += parseInt(($element.css('margin-bottom') || '0').replace('px', ''), 10);

    return styledHeight;
  }

  onWindowResize () {
    this.setMq();
    this.updateMobileModals();
  }

  updateMobileModals () {
    if (!this.isMobile()) {
      $('#nav-modal').modal('hide');
    }
  }
}

//-----------------------------------------------------------------------------------------------------------------------
// Header
class Header {
  constructor () {
    this.adjustNavHeightTimeout = 0;
    this.$siteContainer = $('.site-container');
    this.$header = $('header.main-header');
    this.$content = $('.main-content');
  }

  init () {
    const self = this;

    // Open/Close the menu (mobile only)
    $('.main-nav-toggle').on('click', () => {
      $('.main-nav').toggle();
      $('.main-header-social').toggle();
      this.onWindowResize();
    });

    // Main nav dropdowns
    $('nav.main-nav ul li .trigger-dropdown').click(e => {
      const $this = $(e.currentTarget);

      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
      self.adjustSiteContainerHeight(); // because nav gets taller when used
    });

    $('#bc-category-list-mobile ul li .trigger-dropdown').click(e => {
      const $this = $(e.currentTarget);
      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
      self.adjustSiteContainerHeight(); // because nav gets taller when used
    });

    $(document).on('mousedown', function (e) {
      if ($(e.target).closest('.main-nav-category-header').length === 0 &&
          $(e.target).closest('.main-nav-subcategories').length === 0) {
        $('.main-nav-category').removeClass('selected');
        // $(document).off("mousedown.hidesubcategories");
      }
    });

    // Open search bar (mobile only)
    $('.main-header-search-icon').on('click', e => {
      if (!main.isMobile()) {
        main.handleSearch(e);
        return;
      }
      $(e.currentTarget).parents('.main-header-search').toggleClass('open');
      this.adjustSearchWidth();
    });

    // "enter" for search
    $('.main-header-search-input').on('keyup', function (e) {
      if (e.keyCode === 13) {// enter
        if (e.originalEvent.isComposing) { // don't execute search if keyboard is composing input
          return;
        }
        main.handleSearch(e);
      }
    });
  }

  adjustSocialWidth () {
    const width = $(window).width() - 70;// 70px = toggle width
    $('.main-header-social').width(width);
  }

  adjustSearchWidth () {
    const $search = $('.main-header-search');
    const $input = $search.find('input');
    const width = $(window).width() - 70;// 70px = toggle width

    if ($search.hasClass('open')) {
      $search.width(width);
      $input.width(width - 90);// 90px = toggle + 20 padding
    } else {
      $search.width('');
      $input.width('');
    }
  }

  adjustSiteContainerHeight () {
    const self = this;

    self.$siteContainer.css('height', ''); //reset height
    if (self.$header.height() > self.$siteContainer.height()) {
      self.$siteContainer.css('min-height', self.$header.height());
    }
    if (self.$content.height() > self.$siteContainer.height()) {
      self.$siteContainer.css('min-height', self.$content.height());
    }
  }

  onWindowResize () {
    this.adjustSocialWidth();
    this.adjustSearchWidth();
    this.adjustSiteContainerHeight();
  }
}

const VIDEO_ASPECT_RATIO = 16 / 9;
const VIDEO_ASPECT_WIDTH = 16;
const VIDEO_ASPECT_HEIGHT = 9;

//---------------------------------------------------------------------------------
// Carousel
class Carousel {
  constructor () {
    this.currentVideo = null;
    this.currentVideoSlideIndex = -1;
    this.templates = [];
    this.resizeTimeout = 0;
  }

  init () {
    this.bindEventHandlers();

    $('.home-carousel').on('afterChange', () => this.onAfterSlideChange());

    const slickOptions = {
      dots: true,
      infinite: true,
      draggable: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    if (main.isMobile()) {
      slickOptions.draggable = true;
      slickOptions.centerMode = true;
      slickOptions.centerPadding = '15px';
      // disable infinite carousel if there is only one video
      if ($('.home-carousel').children().length === 1) {
        slickOptions.infinite = false;
      }
    }

    // for a11y we need to set the aria-hiden & tabindex for the play icon to gain focus
    $('.home-carousel').on('init afterChange', function () {
      setTimeout(function () {
        $('.slick-slide.slick-current').attr('aria-hidden', false);
        $('.slick-slide.slick-current button').attr('tabindex', '0');
      }, 100);
    });
    $('.home-carousel').slick(slickOptions);
  }

  bindEventHandlers () {
    // click on carousel item
    $('.home-carousel-slide').on('click', '.bc-player-placeholder', (e) => {
      e.preventDefault();

      const $slide = $(e.currentTarget).closest('.home-carousel-slide');
      this.currentVideoSlideIndex = $slide.attr('data-slide-index');
      this.loadVideo($slide.attr('data-video-id'));
    });
  }

  resizeVideos () {
    $('.home-carousel-slide').each(function () {
      const $videoContainer = $(this).find('.home-carousel-video');

      $videoContainer.css('height', '');
      $videoContainer.css('width', '');
      const $video = $(this).find('.bc-player');
      let width = $videoContainer.innerWidth();
      width = Math.floor(width / VIDEO_ASPECT_WIDTH) * VIDEO_ASPECT_WIDTH;
      const height = Math.floor(width / VIDEO_ASPECT_RATIO);
      $video.innerHeight(height + 'px');
      $video.innerWidth(width + 'px');

      const $image = $(this).find('.bc-image-loader');
      $image.innerHeight(height + 'px');
    });
  }

  resizeArrows () {
    const $arrows = $('.slick-prev, .slick-next');
    const arrowHeight = $('.home-carousel').outerHeight() / 2;
    $arrows.height(arrowHeight);
  }

  positionCarouselDots () {
    const $carousel = $('.home-carousel');
    let position;

    if (main.mq === 320) {
      position = $carousel.find('.home-carousel-video').height() - 20;
    } else if (main.mq === 600) {
      position = $carousel.find('.home-carousel-video').height() + 25;
    } else {
      position = $carousel.find('.home-carousel-video').height() + 50;
    }

    $carousel.find('.slick-dots').css({
      bottom: 'auto',
      top: position,
    });
  }

  loadVideo (videoId) {
    // var url = window.bcGallery.getTemplatePath('/sites/the_classic/json/video.json');// @TODO - remove for production, used for sandbox testing
    const url = window.baseUrl + '/api/video/' + videoId;

    $.ajax(url, {
      dataType: 'json',
      success: data => this.loadSlideVideoPlayer(data),
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadSlideVideoPlayer (data) {
    if (!data || !data.result) {
      return;
    }

    this.currentVideo = data.result;
    this.loadTemplate(utils.getPlayerTemplateUrl(), (template) => {
      const html = template({
        url: window.location.href,
        video: this.currentVideo,
        isSecure: window.location.protocol === 'https:',
        'user-agent': window.navigator.userAgent,
        autoplay: true,
        playerBackground: window.playerBackground,
        subPath: window.subPath,
        player: window.bc_gallery.player,
        site: window.site,
      });

      let $slide = $('.slick-active .home-carousel-slide[data-slide-index=' + this.currentVideoSlideIndex + ']');
      // This case handles 1 video in collection
      if ($slide.length === 0) {
        if (this.currentVideoSlideIndex === '1') {
          $slide = $('.slick-slide .home-carousel-slide');
        }
      }
      $slide.find('.bc-player-placeholder').hide();

      if ($slide.length) {
        teardownPlayers($slide.get(0));
        $slide.find('.bc-player').html(html).show();
        setupPlayers($slide.get(0));
      }

      this.onWindowResize();
    });
  }

  onAfterSlideChange () {
    this.deleteVideos();
  }

  deleteVideos () {
    $('.home-carousel-slide').each(function () {
      const $slide = $(this);
      $slide.find('.bc-player').html('').hide();
      $slide.find('.bc-player-placeholder').show();
    });

    this.onWindowResize();
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }

    $.ajax({
      url: url,
      success: (contents) => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onWindowResize () {
    this.resizeVideos();// order matters
    this.resizeArrows();// order matters
    this.positionCarouselDots();// order matters

    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.resizeVideos();// order matters
      this.resizeArrows();// order matters
      this.positionCarouselDots();// order matters
    }, 100);
  }
}

//---------------------------------------------------------------------------------
// Video Detail
class Detail {
  init () {
    // Show comments section
    $('.view-comments').on('click', e => {
      $(e.currentTarget).siblings('.comments-content').toggle();
      header.onWindowResize();// comments add height
    });
  }

  onWindowResize () {
    this.resizeVideoPlayer();
  }

  resizeVideoPlayer () {
    const $video = $('.video-detail-video');
    const $videoContainer = $('.video-detail');
    const $videoInfo = $('.video-detail-info');

    const containerWidth = $videoContainer.width();
    let targetWidth = Math.floor(containerWidth / VIDEO_ASPECT_WIDTH) * VIDEO_ASPECT_WIDTH;
    let targetHeight = targetWidth / VIDEO_ASPECT_RATIO;

    // adjust target width and height to be a clean multiple of the aspect ratio to prevent black borders
    targetHeight = Math.floor(targetHeight / VIDEO_ASPECT_HEIGHT) * VIDEO_ASPECT_HEIGHT;

    //Adjust width for mobile full bleed view
    if (main.isMobile()) {
      targetWidth += 20;
      targetHeight += 15;
    }
    $video.width(targetWidth);
    $video.height(targetHeight);

    if (!main.isMobile()) {
      // center video and info based on new width on desktop
      const targetMargin = Math.floor((containerWidth - targetWidth) / 2);
      $video.css({
        marginLeft: targetMargin + 'px',
        marginRight: targetMargin + 'px',
      });
      $videoInfo.css({
        marginLeft: targetMargin + 'px',
        marginRight: targetMargin + 'px',
      });
    }
  }
}

//--------------------------------------------------------------------------------
// Secondary Nav
class SecondaryNav {
  init () {
    const self = this;

    $('#secondary-nav ul li .trigger-dropdown').click(e => {
      const $this = $(e.currentTarget);
      self.closeAllDropdows($this.closest('.top-level'));

      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });

    $(document).click(function (event) {
      if (!$(event.target).closest('li.top-level').length) {
        self.closeAllDropdows();
      }
    });
  }
  closeAllDropdows (excludedElement) {
    $('#secondary-nav > ul > li.active-dropdown').each(function () {
      const $this = $(this);

      // closeAllDropdowns gets called on every .trigger-dropdown click
      // we exclude a top level dropdown that is the parent of a dropdown being activated
      if (excludedElement && $this[0] === excludedElement[0]) {
        return;
      }

      $this.children('ul').toggleClass('show-dropdown hide-dropdown');
      $this.removeClass('active-dropdown');
    });
  }
}

const main = new Main();
const carousel = new Carousel();
const detail = new Detail();
const header = new Header();
const secondaryNav = new SecondaryNav();

$(function () {
  main.init();
  carousel.init();
  detail.init();
  header.init();
  secondaryNav.init();

  main.onWindowResize();
  carousel.onWindowResize();
  detail.onWindowResize();
  header.onWindowResize();

  // always load images in 16:9 ratio, helps IE 11
  window.bcGallery.imageLoader.ignoreHeight = true;
  window.bcGallery.imageLoader.loadImages();
  // the carousel dots need to repositioned after images are loaded
  window.bcGallery.imageLoader.loaded = function () {
    carousel.onWindowResize();
  };
});

let windowResizeTimeout;
$(window).resize(function () {
  window.clearTimeout(windowResizeTimeout);
  windowResizeTimeout = setTimeout(function () {
    main.onWindowResize();
    carousel.onWindowResize();
    detail.onWindowResize();
    header.onWindowResize();
  }, 100);
});

$(window).on('load', function () {
  header.onWindowResize();
});

window.BCLS = window.BCLS || {};
window.BCLS.config = {
  reloadOnChange: false,
};
window.BCLS.afterReadyHandler = function () {
  carousel.onWindowResize();
  window.BCLS.modVP.play();
};
